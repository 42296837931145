/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { CircleSlider } from 'react-circle-slider';
import { IPlansCardResponse } from '../../common/interfaces/Plans';
import Text from '../CustomTextFormatter/Text';
import Card from '../Card/Card';
import {
  cardStyle,
  NameAndActiveContainer,
  planCaptionStyle,
  PlanInfoContainer,
  PlanInfoLeftSide,
  planNameStyle,
  ValidityAndNameContainer,
  validityRangeStyle,
} from './HomePlanCard.style';
import { PATH_CONSTANTS } from '../../helper/constants';
import Macronutrients from '../../pages/athlete/Home/components/Macronutrients/Macronutrients';
import { IMacronutrients } from '../../common/interfaces/User';
import { calculateCompletationPlanPercentage, calculateDaysLeft, calculateTotalPlanDays } from '../../helper/utility';

interface Props {
  plan: IPlansCardResponse | undefined,
  macronutrients:IMacronutrients | any
}

export default function HomePlanCard({ plan, macronutrients } :Props) {
  const navigate = useNavigate();
  const { PLANS_PATH } = PATH_CONSTANTS;

  let percentage = 0;
  if (plan?._id) {
    const totalPlanDays = calculateTotalPlanDays(plan.startDate, plan.endDate);
    const daysLeft = calculateDaysLeft(plan.endDate);
    percentage = calculateCompletationPlanPercentage(daysLeft, totalPlanDays);
  }

  return (
    <Card
      additionalStyle={{ ...cardStyle, pointerEvents: plan?._id ? 'auto' : 'none' }}
      onClick={() => navigate(`${PLANS_PATH}/${plan?._id}`)}
    >
      <Stack
        direction="column"
        justifyContent="space-evenly"
        spacing={2}
      >

        <PlanInfoContainer>
          {plan?._id && (
          <>
            <PlanInfoLeftSide>
              <ValidityAndNameContainer>
                <Text type="body1" additionalStyle={validityRangeStyle}>
                  {`${format(new Date(plan.startDate), 'dd/MM/yy')} to ${format(new Date(plan.endDate), 'dd/MM/yy')}`}
                </Text>

                <NameAndActiveContainer>
                  <Text type="h3" additionalStyle={planNameStyle}>
                    {plan.name}
                  </Text>
                </NameAndActiveContainer>
              </ValidityAndNameContainer>

              <Text
                type="body1"
                additionalStyle={planCaptionStyle}
              >
                {plan.caption}
              </Text>
            </PlanInfoLeftSide>

            <CircleSlider
              progressColor="rgba(67,67,67,1)"
              circleColor="#F9F9F9"
              size={150}
              value={percentage}
              disabled
              showPercentage
              progressWidth={16}
              circleWidth={16}
              tooltipSize={20}
              tooltipColor="#F9F9F9"
              showTooltip
              knobColor="transparent"
            />
          </>
          )}
        </PlanInfoContainer>

        <Macronutrients macronutrients={macronutrients} />

        {!plan?._id && (
        <Text type="body1" additionalStyle={{ textAlign: 'center' }}>
          Non hai ancora un piano attivo.
          Il tuo personal trainer sta lavorando per te!
        </Text>
        )}

      </Stack>
    </Card>

  );
}
