/* eslint-disable max-len */
import { differenceInDays } from 'date-fns';

// eslint-disable-next-line no-unused-vars
export const calculateDaysLeft : (endDate: number) => number = (endDate: number) => (new Date(endDate) > new Date()
  ? differenceInDays(new Date(endDate), new Date()) : 0);

// eslint-disable-next-line no-unused-vars
export const calculateTotalPlanDays : (startDate: number, endDate: number) => number = (startDate: number, endDate: number) => differenceInDays(new Date(endDate), new Date(startDate));

// eslint-disable-next-line no-unused-vars
export const selectHomePlanProgressBarLabel : (endDate: number) => string = (endDate: number) => (new Date(endDate) > new Date()
  ? `${differenceInDays(new Date(endDate), new Date())} days left` : 'Completed');

// eslint-disable-next-line no-unused-vars
export const calculateCompletationPlanPercentage : (daysLeft: number, totalPlanDays: number) => number = (daysLeft: number, totalPlanDays: number) => (daysLeft > 0 ? 100 - Math.ceil(((daysLeft * 100) / totalPlanDays)) : 100);

export const convertHMS = (value: number) => {
  let hours: number|string = Math.floor(value / 3600); // get hours
  let minutes: number|string = Math.floor((value - (hours * 3600)) / 60); // get minutes
  let seconds: number|string = value - (hours * 3600) - (minutes * 60); //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) { hours = `0${hours}`; }
  if (minutes < 10) { minutes = `0${minutes}`; }
  if (seconds < 10) { seconds = `0${seconds}`; }
  return `${minutes}'${seconds}''`; // Return is HH : MM : SS
};

export const groupPlanByDayAndMuscleGroup = (plan: any) => {
  const groupByDay = plan?.plan?.exercise?.reduce((group: any, product: any) => {
    const { day } = product;
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    group[day] = group[day] ?? [];
    // @ts-ignore
    group[day].push(product);
    return group;
  }, {});
  // @ts-ignore
  Object.keys(groupByDay).forEach((day) => {
    // @ts-ignore
    const groupByMg = groupByDay[day].reduce((group, product) => {
      const { name } = product.exercise.muscleGroups[0].muscleGroup;
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      group[name] = group[name] ?? [];
      // @ts-ignore
      group[name].push(product);
      return group;
    }, {});
    // @ts-ignore
    groupByDay[day] = groupByMg;
  });
  return groupByDay;
};

export function getKeyByValue(object: Record<string, unknown>, value: string | number) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function swapElements(arr: any, i1: number, i2: number) {
  // eslint-disable-next-line no-param-reassign,prefer-destructuring
  arr[i1] = arr.splice(i2, 1, arr[i1])[0];
  return arr;
}
