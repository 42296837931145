import { styled } from '@mui/system';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';

interface Props {
  imageUrl: string | undefined;
}

export const CustomCard = styled(Card)`
  position: relative;
  `;

export const CustomCardContent = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  height: 100%;
  `;

// @ts-ignore
export const BGBox = styled(Box)<Props>`
  background: ${({ imageUrl }) => (`url(${imageUrl})`)};
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.15;
  content: "";
  `;
