import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const BackdropBox = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  `;
