import React from 'react';
import Avatar from '@mui/material/Avatar';
import HomeBarController from './controller/HomeBarController';
import TitleBar from '../TitleBar/TitleBar';
import { HOME_BAR_CONSTANTS } from '../../helper/constants';
import Text from '../CustomTextFormatter/Text';
import NotificationButton from './components/NotificationButton/NotificationButton';
import { TextStyle } from './HomeBar.style';

export default function HomeBar({ onClickOnAvatar } : { onClickOnAvatar: () => void}) {
  const { You, Hello } = HOME_BAR_CONSTANTS;
  const { avatarUrl, user, notificationNumber } = HomeBarController();
  return (
    <TitleBar>
      <>
        <Avatar onClick={onClickOnAvatar} alt={You} src={avatarUrl} />
        <Text type="h1" additionalStyle={TextStyle}>
          {`${Hello}${user?.name || 'Guest'}`}
        </Text>
        <NotificationButton notificationNumber={notificationNumber} />
      </>
    </TitleBar>
  );
}
