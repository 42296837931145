import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { PALETTE } from '../../../../helper/constants';

interface Props {
  onClick: () => void;
}

const { WHITEISH } = PALETTE;

export const notificationIconStyle = {
  width: '1.5rem',
  height: '1.5rem',
  color: WHITEISH,
};

export const CircleBox = styled(Box)<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 2.25rem;
  height: 2.25rem;
  top: 0;
  background: #FE5F55;
  backdrop-filter: blur(0.25rem);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 3.125rem;
  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 2;
  `;
