import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const StyledBox = styled(Box)`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 0.625rem;
  isolation: isolate;
  position: relative;
  width: 100%;
  height: 2.25rem;

  background: transparent;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
  `;
