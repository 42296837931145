import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const LayoutContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem 0 1rem;
  gap: 1.25rem;
  isolation: isolate;
  background: linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(54,54,54,1) 28%, rgba(38,38,38,1) 64%, rgba(29,29,29,1) 85%, rgba(22,22,22,1) 100%);
  mix-blend-mode: normal;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  /* Inside auto layout */
  //min-height: calc(100% - 6.5rem);
  height: calc(100vh - 3.5rem);
  overflow: auto;
  overflow-x: hidden;
  flex: none;
  order: 1;
  flex-grow: 0;
  box-sizing: border-box;
  `;
