import React from 'react';
import Card from '../Card/Card';
import Text from '../CustomTextFormatter/Text';
import { ContentContainer, IconContainer } from './ShortcutCard.style';

export default function ShortcutCard({
  label, icon, onClick, additionalStyle,
} : { label: string, icon: JSX.Element, onClick: ()=>void, additionalStyle: Record<string, unknown> }) {
  return (
    <Card
      additionalStyle={additionalStyle}
      onClick={onClick}
    >
      <ContentContainer>
        <IconContainer>
          {icon}
        </IconContainer>
        <Text type="body1">
          {label}
        </Text>
      </ContentContainer>
    </Card>

  );
}
