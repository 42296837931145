import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const BoxContainer = styled(Box)`
  border: 0.01rem solid rgba(255, 255, 255, 0.1);
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const textStyle = { textAlign: 'center' };
