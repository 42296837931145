/* eslint-disable react/jsx-no-constructed-context-values,react/jsx-props-no-spreading */
import React, { createContext, useReducer } from 'react';

interface IPageRenderingState {
    orientation: string | undefined ;
}

interface IPageRenderingAction {
    type: 'CHANGE_ORIENTATION';
    payload?: string;
}

const initialState: IPageRenderingState = {
  orientation: 'left',
};

const PageRenderingContext = createContext({
  orientation: 'left',
  changeOrientation: (orientation: string) => initialState,
});

function pageRenderingReducer(state: IPageRenderingState, action: IPageRenderingAction) {
  switch (action.type) {
    case 'CHANGE_ORIENTATION':
      return {
        ...state,
        orientation: action.payload,
      };
    default:
      return state;
  }
}

function PageRenderingProvider(props: any) {
  // eslint-disable-next-line max-len
  const [state, dispatch] = useReducer<(state1: IPageRenderingState, action1: IPageRenderingAction) => IPageRenderingState>(pageRenderingReducer, initialState);

  function changeOrientation(orientation: string) {
    dispatch({
      type: 'CHANGE_ORIENTATION',
      payload: orientation,
    });
  }

  return (
    <PageRenderingContext.Provider
      value={{
        orientation: state.orientation, changeOrientation,
      }}
      {...props}
    />
  );
}

export { PageRenderingContext, PageRenderingProvider };
