import React, { FunctionComponent } from 'react';
import { Typography } from '@mui/material';

interface Props {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1'
      | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button'
      | 'overline' | 'inherit' | undefined,
  additionalStyle?: Record<string, unknown>
  children: string
  onClick?: () => void
}

const Text: FunctionComponent<Props> = ({
  type, additionalStyle, children, onClick,
} : Props) => (
  <Typography component="p" variant={type} sx={{ color: '#F9F9F9', ...additionalStyle }} onClick={onClick}>
    {children}
  </Typography>
);

export default Text;
