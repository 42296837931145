import React from 'react';
import { StyledBox } from './TitleBar.style';

interface Props {
  children: JSX.Element,
  additionalStyle? : any
}

export default function TitleBar({ children, additionalStyle }: Props) {
  return (
    <StyledBox sx={additionalStyle}>
      {children}
    </StyledBox>
  );
}
