import {
  Dispatch, SetStateAction, useContext, useState,
} from 'react';
import { AuthContext } from '../../../../context/auth';

type ControllerFN = () => {
    onChangeDate: (number: string) => void
    selectedDay: string
    openUserPopup: boolean,
    setOpenUserPopup: Dispatch<SetStateAction<boolean>>
    logout: () => void
};

const curr = new Date();

const HomePageController: ControllerFN = () => {
  const [selectedDay, setSelectedDay] = useState<string>(curr.getUTCDate().toString());
  const [openUserPopup, setOpenUserPopup] = useState<boolean>(false);

  const context = useContext(AuthContext);

  const onChangeDate = (number: string) => {
    setSelectedDay(number);
  };

  const logout = () => {
    context.logout();
  };

  return {
    selectedDay, onChangeDate, openUserPopup, setOpenUserPopup, logout,
  };
};

export default HomePageController;
