import React from 'react';
import Text from '../CustomTextFormatter/Text';
import { BoxContainer, Container, textStyle } from './DetailsBox.style';

interface Props {
    children: JSX.Element,
    additionalStyle?: Record<string, unknown>,
    containerAdditionalStyle?: Record<string, unknown>,
    additionalLabelStyle?: Record<string, unknown>,
    label? : string
    onClick?: () => void
}

export default function DetailsBox({
  children, additionalStyle, label, onClick, containerAdditionalStyle, additionalLabelStyle,
}: Props) {
  return (
    <Container onClick={onClick} sx={containerAdditionalStyle}>
      <BoxContainer style={additionalStyle}>
        {children}
      </BoxContainer>
      {label && (
      <Text additionalStyle={{ ...textStyle, ...additionalLabelStyle }} type="body2">
        {label}
      </Text>
      )}
    </Container>
  );
}
