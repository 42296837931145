import React from 'react';
import { BackdropBox } from './Backdrop.style';

interface Props {
    children: JSX.Element,
    additionalStyle?: Record<string, unknown>
}

export default function Backdrop({ children, additionalStyle }: Props) {
  return (
    <BackdropBox style={additionalStyle}>
      {children}
    </BackdropBox>
  );
}
