/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../context/auth';
import { IUser } from '../../../common/interfaces/User';

type ControllerFN = () => {
    avatarUrl: string;
    user: IUser | null;
    // eslint-disable-next-line no-unused-vars
    notificationNumber: number;
};

const HomeBarController: ControllerFN = () => {
  const { user } = useContext(AuthContext);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [notificationNumber, setNotificationNumber] = useState(0);

  return { avatarUrl, user, notificationNumber };
};

export default HomeBarController;
