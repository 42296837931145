import Badge from '@mui/material/Badge';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { CircleBox, notificationIconStyle } from './NotificationButton.style';
import { PATH_CONSTANTS } from '../../../../helper/constants';

export default function NotificationButton({ notificationNumber } : {notificationNumber: number}) {
  const { NOTIFICATION_PATH } = PATH_CONSTANTS;
  const navigate = useNavigate();
  return (
    <CircleBox onClick={() => navigate(NOTIFICATION_PATH)}>
      <Badge badgeContent={notificationNumber} color="secondary">
        <FontAwesomeIcon
          style={notificationIconStyle}
          icon={faBell}
        />
      </Badge>
    </CircleBox>
  );
}
