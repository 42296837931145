import React from 'react';
import { BGBox, CustomCard, CustomCardContent } from './Card.style';

interface Props {
    children: JSX.Element,
    additionalStyle?: Record<string, unknown>,
    cardContentStyle?: Record<string, unknown>,
    hasBackgroundImage?: boolean,
    imageUrl?: string,
    onClick?: () => void
}

export default function Card({
  children, additionalStyle, hasBackgroundImage, imageUrl, cardContentStyle, onClick,
}: Props) {
  return (
    <CustomCard sx={additionalStyle} onClick={onClick}>
      {hasBackgroundImage && <BGBox imageUrl={imageUrl} />}
      <CustomCardContent sx={cardContentStyle}>
        {children}
      </CustomCardContent>
    </CustomCard>
  );
}
