/* eslint-disable react/jsx-no-constructed-context-values,react/jsx-props-no-spreading */
import React, { createContext, useReducer } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { IUser } from '../common/interfaces/User';

interface IAuthState {
  user: null | IUser | undefined;
}

interface IAuthAction {
  type: 'LOGIN' | 'LOGOUT';
  payload?: IUser | undefined | null;
}

const initialState: IAuthState = {
  user: null,
};

if (localStorage.getItem('jwt')) {
  const decodedToken: JwtPayload = jwtDecode(window.localStorage.getItem('jwt') || '');

  if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem('jwt');
  }
}

const AuthContext = createContext({
  user: null,
  // eslint-disable-next-line no-unused-vars
  login: (userData: IUser) => initialState,
  logout: () => initialState,
});

function authReducer(state: IAuthState, action: IAuthAction) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}

function AuthProvider(props: any) {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useReducer<(state1: IAuthState, action1: IAuthAction) => IAuthState>(authReducer, initialState);

  function login(userData: IUser) {
    if (userData.token) {
      window.localStorage.setItem('jwt', userData.token);
    }
    dispatch({
      type: 'LOGIN',
      payload: userData,
    });
  }

  function logout() {
    localStorage.removeItem('jwt');
    dispatch({ type: 'LOGOUT' });
  }

  return (
    <AuthContext.Provider
      value={{ user: state.user, login, logout }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
