import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const PlanInfoContainer = styled(Box)`
  border-radius: 1.2rem;
  padding: 0 0 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  `;

export const NameAndActiveContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 1rem;
  `;

export const PlanInfoLeftSide = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  `;

export const ValidityAndNameContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  `;

export const cardStyle = {
  background: 'rgba(255, 255, 255, 0.01)',
  border: '0.01rem solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0.31rem 0.31rem 0.25rem rgba(0, 0, 0, 0.08)',
  borderRadius: '1.2rem',
  width: '100%',
  minHeight: '30vh',
  overflow: 'auto',
};

export const cardContentStyle = {
  background: '-webkit-linear-gradient(45deg, hsla(210, 18%, 20%, 1) 0%, hsla(215, 27%, 47%, 1) 73%)',
  filter: 'progid: DXImageTransform.Microsoft.gradient( startColorstr="#2A333C", endColorstr="#577399", GradientType=1 )',
  height: '100%',
};

export const planNameStyle = { color: 'white', flexGrow: 1 };
export const validityRangeStyle = { color: 'white', zIndex: 1, letterSpacing: '-0.1px' };
export const planCaptionStyle = {
  color: 'white', zIndex: 1, letterSpacing: '-1px',
};
