import React, { useContext, useEffect, useState } from 'react';
import Slide from '@mui/material/Slide';
import { LayoutContainer } from './Layout.style';
import { PageRenderingContext } from '../../context/pagerendering';

interface Props {
    children: JSX.Element | null,
    additionalStyle?: Record<string, unknown>
}

export default function Layout({ children, additionalStyle }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [orientation, setOrientation] = useState<string>('left');

  const orientationContext = useContext(PageRenderingContext);

  useEffect(() => {
    setOpen(true);
    setOrientation(orientationContext.orientation);
    return () => {
      setOpen(false);
    };
  }, []);

  useEffect(() => {
    if (orientation === 'right') {
      orientationContext.changeOrientation('left');
    }
  }, [orientation]);

  return (
  // @ts-ignore
    <Slide direction={orientation} in={open} mountOnEnter unmountOnExit>
      <LayoutContainer style={additionalStyle}>
        {children}
      </LayoutContainer>
    </Slide>

  );
}
