export const HOME_BAR_CONSTANTS = {
  Hello: 'Ciao, ',
  You: 'Tu',
};

export const PATH_CONSTANTS = {
  HOME_PATH: '/dashboard',
  PROFILE_PATH: '/profile',
  NOTIFICATION_PATH: '/notification',
  PLANS_PATH: '/plans',
  REPORTS_PATH: '/reports',
  TRAINING_PATH: '/training',
  HOME_PT_PATH: '/pt/dashboard',
  ATHLETES_PT_PATH: '/pt/athletes',
  PHASES_PT_PATH: '/pt/phases',
  ADD_ATHLETE_PT_PATH: '/pt/addAthlete',
  ADD_EXERCISE_PT_PATH: '/pt/addExercise',
  ADD_MUSCLE_GROUP_PT_PATH: '/pt/addMuscleGroup',
  ADD_TRAINING_PHASE_PT_PATH: '/pt/addTrainingPhase',
  EDIT_ATHLETE_PT_PATH: '/pt/editAthlete',
  EDIT_EXERCISE_PT_PATH: '/pt/editExercise',
  EDIT_MUSCLE_GROUP_PT_PATH: '/pt/editMuscleGroup',
  EDIT_TRAINING_PHASE_PT_PATH: '/pt/editTrainingPhase',
  ADD_PLAN_GROUP_PT_PATH: '/pt/addPlan',
  EDIT_PLAN_PT_PATH: '/pt/editPlan',
  PLANS_PT_PATH: '/pt/plans',
  EXERCISES_PT_PATH: '/pt/exercises',
  MUSCLE_GROUPS_PT_PATH: '/pt/muscleGroups',
  REPORTS_PT_PATH: '/pt/reports',
};

export const PALETTE = {
  WHITEISH: '#F9F9F9',
  WHITEISH_RGB: 'rgb(247, 247, 255)',
  ORANGEISH: '#FE5F55',
  ORANGEISH_RGB: 'rgb(254, 95, 85)',
  YELLOWISH: '#FAEEDB',
  YELLOWISH_RGB: 'rgb(250, 238, 219)',
  BLUEISH: '#577399',
  BLUEISH_RGB: 'rgb(87, 115, 153)',
  DARKBLUEISH: '#2A333C',
  DARKBLUEISH_RGB: 'rgb(42, 51, 60)',

};

export const TEXT_STYLE = {
  TITLE: 'h1',
  SUBTITLE: 'h2',
  ORANGEISH: '#FE55F55',
  ORANGEISH_RGB: 'rgb(254, 95, 85)',
};

export const MUSCLE_GROUPS_ICON = {
  DORSAL: 'dorsal',
  CARDIO: 'cardio',
  ABDOMINAL: 'abs',
  BACK: 'back',
  CHEST: 'chest',
  LEGS: 'legs',
  BICEPS: 'biceps',
  TRICEPS: 'triceps',
};
